import { useEffect, useState } from "react";
import {
    Link,
} from "react-router-dom";
import axios from 'axios';
import { supabase } from "../resources/supabase";
import { uid } from 'uid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Trumbowyg from 'react-trumbowyg';
import 'react-trumbowyg/dist/trumbowyg.min.css';

export function AffiliateManagement({ }) {
    const [storiesContent, setStoriesContent] = useState([]);
    const [openPostDetail, setOpenPostDetail] = useState(false);
    const [idContent, setIdContent] = useState('');
    const [linkContent, setLinkContent] = useState('');
    const [titleContent, setTitleContent] = useState('');
    const [subtitleContent, setSubtitleContent] = useState('');
    const [stringArray, setStringArray] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState('');

    function handleAddString() {
        if (inputValue) {
            setStringArray([...stringArray, inputValue]);
            setInputValue('');
        }
    }

    function handleRemoveString(index) {
        const updatedArray = [...stringArray];
        updatedArray.splice(index, 1);
        setStringArray(updatedArray);
    }

    function handleDropdownChange(e) {
        const selectedValue = e.target.value;
        if (selectedValue) {
            setStringArray([...stringArray, selectedValue]);
        }
    }

    const getCategory = async (id) => {
        let { data, error } = await supabase
            .from('affiliate')
            .select('category')
            .eq('id', id)

        setStringArray(data[0].category)
    }

    const getCategories = async (e) => {
        let { data: affiliateCategoryData, error } = await supabase
            .from('affiliateCategory')
            .select('*')

        let dataArray = [];
        for (let i = 0; i < affiliateCategoryData.length; i++) {
            dataArray.push(affiliateCategoryData[i].name)
        }
        setCategoriesData(dataArray);
    };

    useEffect(() => {
        getCategories();
    }, []);

    const [toastMessage, setToastMessage] = useState('');
    const [isToast, setIsToast] = useState(false);

    const clearAllForm = () => {
        setTitleContent('');
        setSubtitleContent('');
        setLinkContent('');
        setIdContent('');
    };

    const getDataStories = async () => {
        const { data: response } = await supabase
            .from('affiliate')
            .select('*')
            .order('created_at', { ascending: false })

        setStoriesContent(response);
    }

    const getDataStoriesSlug = async (response) => {
        setIdContent(response.id);
        setTitleContent(response.title);
        setSubtitleContent(response.subtitle);
        setLinkContent(response.link);
        setOpenPostDetail(true);
    }

    const updateStatus = async (id, status) => {
        try {
            if (status === true) {
                await supabase
                    .from('affiliate')
                    .update({ isPublished: true })
                    .eq('id', id)
            } else {
                await supabase
                    .from('affiliate')
                    .update({ isPublished: false })
                    .eq('id', id)
            }

            getDataStories();
        } catch (error) {
            console.log(error);
        }
    }

    const updateDataStories = async (id) => {

        try {
            await supabase
                .from('affiliate')
                .update({
                    title: titleContent,
                    subtitle: subtitleContent,
                    link: linkContent,
                    category: stringArray
                })
                .eq('id', id)

            let uniqueValues = stringArray.filter(value => !categoriesData.includes(value));

            for (let i = 0; i < uniqueValues.length; i++) {
                await supabase
                    .from('affiliateCategory')
                    .insert([{
                        name: uniqueValues[i],
                    }])
            }

            clearAllForm();
            getDataStories();
            setOpenPostDetail(false)
            setIsToast(true);
            setToastMessage('Successfully Updated!');
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
            setOpenPostDetail(false)
        }
    }

    const deleteDataStories = async (id) => {
        try {
            await supabase
                .from('affiliate')
                .delete()
                .eq('id', id)

            clearAllForm();
            getDataStories();
            setOpenPostDetail(false);
            setIsToast(true);
            setToastMessage('Story Deleted!');
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    useEffect(() => {
        getDataStories();
    }, []);

    return (
        <div className="flex flex-col flex-1 overflow-x-hidden">
            <main>
                <div className="py-6">
                    <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">

                        <div className="px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-2 md:grid-cols-0 md:flex md:items-start md:justify-between">
                                <div className="w-full">
                                    <p className="text-base font-bold text-gray-900">Affiliate</p>
                                    <p className="mt-1 text-sm font-medium text-gray-500">List of Affiliate.</p>
                                </div>
                                <div className="text-end w-full self-center">
                                    <Link to="/launchy/create-affiliate" className="text-sm drop-shadow-md px-2 py-1 text-gray-900 border rounded hover:bg-gray-50 transition-all duration-150">Create Affiliate +</Link>
                                </div>
                            </div>
                        </div>

                        <div className="divide-y divide-gray-200 border border-gray-200 rounded-xl" style={{
                            height: '50vh',
                            overflowY: 'auto'
                        }}>
                            {storiesContent.map((data, index) => {
                                return (
                                    <div key={index} className="grid gap-y-4 py-2 md:grid-cols-4 md:gap-0">
                                        <div className="px-4 lg:py-4 sm:px-6 self-center">
                                            <p className="text-sm font-bold text-gray-900 truncate">{data.title}</p>
                                        </div>

                                        <div className="px-4 lg:py-4 sm:px-6 self-center">
                                            <p className="text-sm text-gray-900 truncate">{data.subtitle}</p>
                                        </div>

                                        <div className="flex cols-span-2 md:justify-center">
                                            <div className="px-4 lg:py-4 sm:px-6 self-center">
                                                {data.isPublished === false ?
                                                    <span class="text-xs font-medium text-yellow-900 bg-yellow-100 rounded-full inline-flex items-center px-2.5 py-1"><svg class="-ml-1 mr-1.5 h-2.5 w-2.5 text-yellow-400" fill="currentColor" viewBox="0 0 8 8"><circle cx="4" cy="4" r="3"></circle></svg>Draft</span>
                                                    :
                                                    <span class="text-xs font-medium text-green-900 bg-green-100 rounded-full inline-flex items-center px-2.5 py-1"><svg class="-ml-1 mr-1.5 h-2.5 w-2.5 text-green-400" fill="currentColor" viewBox="0 0 8 8"><circle cx="4" cy="4" r="3"></circle></svg>Publish</span>
                                                }
                                            </div>

                                            <div className="md:hidden px-4 lg:py-4 sm:px-6 self-center space-x-1 md:text-center" style={{ minWidth: 'fit-content' }}>
                                                <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 font-bold bg-blue-200"
                                                    onClick={() => {
                                                        getDataStoriesSlug(data);
                                                        getCategory(data.id);
                                                    }}>
                                                    <svg style={{ color: 'white' }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L6.50002 2.79288L12.2071 8.49998L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L9.85355 0.146447Z" fill="white"></path> <path d="M0 9.29289L5.79291 3.49998L11.5 9.20709L5.70711 15H0.5C0.223858 15 0 14.7761 0 14.5V9.29289Z" fill="white"></path> <path d="M8 15H15V14H8V15Z" fill="white"></path>
                                                    </svg>
                                                </button>

                                                {data.isPublished ?
                                                    <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 font-bold bg-red-400"
                                                        onClick={() => {
                                                            updateStatus(data.id, false);
                                                        }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                                                        </svg>
                                                    </button>
                                                    :
                                                    <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 font-bold bg-green-200"
                                                        onClick={() => {
                                                            updateStatus(data.id, true);
                                                        }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" fill="white"></path>
                                                        </svg>
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <div className="hidden md:block px-4 lg:py-4 sm:px-6 self-center space-x-1 md:text-center" style={{ minWidth: 'fit-content' }}>
                                            <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 font-bold bg-blue-400"
                                                onClick={() => {
                                                    getDataStoriesSlug(data);
                                                    getCategory(data.id);
                                                }}>
                                                <svg style={{ color: 'white' }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L6.50002 2.79288L12.2071 8.49998L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L9.85355 0.146447Z" fill="white"></path> <path d="M0 9.29289L5.79291 3.49998L11.5 9.20709L5.70711 15H0.5C0.223858 15 0 14.7761 0 14.5V9.29289Z" fill="white"></path> <path d="M8 15H15V14H8V15Z" fill="white"></path>
                                                </svg>
                                            </button>

                                            {data.isPublished ?
                                                <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 font-bold bg-red-400"
                                                    onClick={() => {
                                                        updateStatus(data.id, false);
                                                    }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                                                    </svg>
                                                </button>
                                                :
                                                <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 font-bold bg-green-200"
                                                    onClick={() => {
                                                        updateStatus(data.id, true);
                                                    }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" fill="white"></path>
                                                    </svg>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </main>

            {/* ================================== Update ================================== */}
            <Dialog
                fullWidth='true'
                maxWidth='lg'
                open={openPostDetail}
            >
                <DialogContent>
                    <DialogContentText>
                        <div className="mt-6">
                            <label class="inline-block text-gray-500">Link</label>
                            <input value={linkContent} onChange={(e) => { setLinkContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="Link" />
                        </div>
                        <div className="mt-6">
                            <label class="inline-block text-gray-500">Category</label>
                            <div>
                                <input
                                    type="text"
                                    className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white p-2.5"
                                    value={inputValue}
                                    onChange={(e) => { setInputValue(e.target.value) }}
                                    placeholder="Enter a new Category"
                                />
                                <button className="px-2 py-1 rounded-md bg-green-400 text-white font-bold" onClick={handleAddString}>Add</button>
                                <select value={selectedValue} onChange={handleDropdownChange} className="ml-2 mt-2 bg-white border border-gray-300 rounded-md pl-4 pr-10 py-1">
                                    <option value="" selected disabled hidden>Select Existing Category</option>
                                    {categoriesData.map((data, index) => {
                                        return (
                                            <option value={`${data}`}>{data}</option>
                                        )
                                    })}
                                </select>
                                <div>
                                    {stringArray.map((item, index) => (
                                        <div
                                            className="bg-red-400 text-white flex"
                                            key={index}
                                            style={{
                                                display: 'inline-block',
                                                margin: '5px',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span className="pl-1 pr-3">{item}</span>
                                            <button className="ml-1 bg-red-600 rounded-full font-black w-6 h-6" onClick={() => handleRemoveString(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x mx-auto" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mt-6">
                            <label class="inline-block text-gray-500">Title</label>
                            <input value={titleContent} onChange={(e) => { setTitleContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="Title" />
                        </div>
                        <div className="mt-6 mb-4">
                            <label class="inline-block text-gray-500">Subtitle</label>
                            <input value={subtitleContent} onChange={(e) => { setSubtitleContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="Subtitle" />
                        </div>

                        <div className='md:space-x-2'>
                            <button onClick={() => { updateDataStories(idContent); }} type="button" className="text-white bg-blue-700 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
                            <button onClick={() => { deleteDataStories(idContent); }} type="button" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Delete</button>
                            <button onClick={() => { clearAllForm(); setOpenPostDetail(false); }} type="button" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Close</button>
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >


            {
                isToast &&
                <div id="toast-bottom-right" style={{ zIndex: '1500' }} className="bg-red-500 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                    <div className="text-sm font-normal flex items-center">
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                        </svg>
                        <span>{toastMessage}</span>
                    </div>
                </div>
            }
        </div>
    );
}