import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import Trumbowyg from 'react-trumbowyg';
import 'react-trumbowyg/dist/trumbowyg.min.css';

export function ProductManagement() {
    var slugify = require('slugify')
    const [productData, setProductData] = useState([]);
    const [productDataLive, setProductDataLive] = useState([]);
    const [dataCategory, setDataCategory] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);

    const [logoProdEd, setLogoProdEd] = useState('');
    const [BannerProdEd, setBannerProdEd] = useState([]);
    const [bannerProduct, setBannerProduct] = useState([]);
    const [bannerProductURLs, setBannerProductURLs] = useState([]);
    const [logoProduct, setLogoProduct] = useState(null);
    const [slugProduct, setSlugProduct] = useState('');
    const [xwalletaddressProduct, setxwalletaddressProduct] = useState('');
    const [nameProduct, setNameProduct] = useState('');
    const [descriptionProduct, setDescriptionProduct] = useState('');
    const [summaryProduct, setSummaryProduct] = useState('');
    const [websiteProduct, setWebsiteProduct] = useState('');
    const [emailProduct, setEmailProduct] = useState('');
    const [twitterProduct, setTwitterProduct] = useState('');
    const [youtubeProduct, setYoutubeProduct] = useState('');
    const [categoryProduct, setCategoryProduct] = useState([]);
    const [dateProduct, setDateProduct] = useState('');

    const [toastMessage, setToastMessage] = useState('');
    const [isToast, setIsToast] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const clearAllForm = () => {
        setSlugProduct('');
        setNameProduct('');
        setSummaryProduct('');
        setDescriptionProduct('');
        setWebsiteProduct('');
        setEmailProduct('');
        setTwitterProduct('');
        setYoutubeProduct('');
        setLogoProdEd('');
        setBannerProdEd([]);
        setCategoryProduct([]);
        setxwalletaddressProduct('');
        setBannerProduct([]);
        setBannerProductURLs([]);
        setLogoProduct(null);
    };

    const getData = async () => {
        const response = await axios.get(`https://api-launchy.rshme.codes/api/v1/product`);
        console.log(response.data.data);

        var pendingData = response.data.data.filter(obj => obj.approved_at === null);
        var liveData = response.data.data.filter(obj => obj.approved_at !== null);

        setProductData(pendingData);
        setProductDataLive(liveData);
    }

    const getDataCategory = async () => {
        const response = await axios.get(`https://api-launchy.rshme.codes/api/v1/category`).catch(function (error) {
            console.log(error);
        });
        setDataCategory(response.data.data);
    }

    const verifiedCheck = async (status, slug, name, xwalletaddress) => {
        let appAt;
        var date = new Date();
        var todayDate =
            date.getFullYear() + "-" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("00" + date.getDate()).slice(-2) + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);

        if (status === "verify") {
            appAt = todayDate;
        } else if (status === "remove") {
            appAt = null;
        }

        //edit data
        let data = {
            "name": name,
            "approved_at": appAt,
        }

        await axios.put(`https://api-launchy.rshme.codes/api/v1/product/${slug}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'X-Wallet-Address': xwalletaddress
            }
        }).catch(function (error) {
            console.log(error);
        });
        getData();
    }

    const deleteDataProduct = async (slug, xwalletaddress) => {
        console.log(xwalletaddress);
        await axios.delete(`https://api-launchy.rshme.codes/api/v1/product/${slug}`, {
            headers: {
                'Content-Type': 'application/json',
                'X-Wallet-Address': xwalletaddress
            }
        }).catch(function (error) {
            console.log(error);
        });

        clearAllForm();
        getData();
        setOpen(false);
    }

    const addDataProduct = async () => {
        var value = summaryProduct;
        var summaryLength = value.length;
        var value2 = descriptionProduct;
        var descLength = value2.length;

        if (nameProduct === '' || summaryProduct === '' || descriptionProduct === '' || websiteProduct === '' || categoryProduct.length === 0 || logoProduct === null || bannerProduct === null || twitterProduct === '') {
            setIsToast(true);
            setToastMessage('Please insert all required form.');
            return;
        }

        if (summaryLength <= 15 || descLength <= 15) {
            setIsToast(true);
            setToastMessage('Please insert Summary or Description form properly.');
            return;
        }

        let ytUrl;
        if (youtubeProduct === '') {
            ytUrl = null;
        } else {
            ytUrl = youtubeProduct;
        }

        let data = {
            "name": nameProduct,
            "tagline": summaryProduct,
            "description": descriptionProduct,
            "website": websiteProduct,
            "email": emailProduct,
            "twitter_url": twitterProduct,
            "youtube_url": ytUrl,
            "categories": categoryProduct,
            "approved_at": dateProduct,
        }

        await axios.post(`https://api-launchy.rshme.codes/api/v1/product`, data, {
            headers: {
                'Content-Type': 'application/json',
                'X-Wallet-Address': '0x49562cCc2BE090B9A7957AE156af9Cc5dbd14765'
            }
        }).catch(function (error) {
            console.log(error);
        }).then(async () => {
            let slug = slugify(nameProduct, {
                lower: true
            });

            const form_data = new FormData();
            if (logoProduct !== null) {
                form_data.append('logo', logoProduct);
            }
            if (bannerProduct.length !== 0) {
                for (let i = 0; i < bannerProduct.length; i++) {
                    form_data.append('banners', bannerProduct[i]);
                }
            }
            await axios.put(`https://api-launchy.rshme.codes/api/v1/product/${slug}/upload`, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Wallet-Address': '0x49562cCc2BE090B9A7957AE156af9Cc5dbd14765'
                }
            }).catch(function (error) {
                console.log(error);
                setIsToast(true);
                setToastMessage('Something wrong, please try again later.');
            });
        })
        clearAllForm();
        getData();
        setOpenCreate(false);
    }

    const editDataProduct = async (slug, xwalletaddress) => {

        let catePro;
        if (categoryProduct.length === 0) {
            catePro = [];
        } else {
            catePro = categoryProduct;
        }

        let ytUrl;
        if (youtubeProduct === '') {
            ytUrl = null;
        } else {
            ytUrl = youtubeProduct;
        }

        // //edit data
        let data = {
            "name": nameProduct,
            "tagline": summaryProduct,
            "description": descriptionProduct,
            "website": websiteProduct,
            "email": emailProduct,
            "twitter_url": twitterProduct,
            "youtube_url": ytUrl,
            "categories": catePro,
        }

        await axios.put(`https://api-launchy.rshme.codes/api/v1/product/${slug}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'X-Wallet-Address': xwalletaddress
            }
        }).catch(function (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
        }).then(async () => {
            // edit image

            const form_data = new FormData();
            if (logoProduct !== null) {
                form_data.append('logo', logoProduct);
            }
            if (bannerProduct.length !== 0) {
                for (let i = 0; i < bannerProduct.length; i++) {
                    form_data.append('banners', bannerProduct[i]);
                }
            }
            await axios.put(`https://api-launchy.rshme.codes/api/v1/product/${slug}/upload`, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Wallet-Address': xwalletaddress
                }
            }).catch(function (error) {
                console.log(error);
                setIsToast(true);
                setToastMessage('Something wrong, please try again later.');
            });
        });
        getData().then(() => {
            clearAllForm();
        });
        handleClose();
    }

    useEffect(() => {
        getData();
        getDataCategory();
    }, []);

    useEffect(() => {
    }, [productData, categoryProduct, xwalletaddressProduct, descriptionProduct, bannerProduct, logoProduct]);

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    useEffect(() => {
        if (bannerProductURLs.length < 1) return;
        bannerProductURLs.forEach((image) => setBannerProduct([...bannerProduct, image]));
    }, [bannerProductURLs]);

    return (
        <div className="flex flex-col flex-1 overflow-x-hidden">
            <section className="pt-10 pb-8 bg-white sm:py-16 lg:pt-24 lg:pb-18">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="max-w-2xl mx-auto text-center">
                        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">How does it work?</h2>
                    </div>

                    <div className="relative mt-12 lg:mt-20">
                        <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                            <img className="w-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg" alt="" />
                        </div>

                        <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                            <div>
                                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span className="text-xl font-semibold text-gray-700"> 1 </span>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Product stored to pending list after user submit request product.</h3>
                            </div>

                            <div>
                                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span className="text-xl font-semibold text-gray-700"> 2 </span>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Admin process all product by checking product information in detail list.</h3>
                            </div>

                            <div>
                                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span className="text-xl font-semibold text-gray-700"> 3 </span>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Click ✓ button to verify product, if product has been processed and ready to publish.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main>
                <div className="py-6">
                    <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">
                        <div className="space-y-5 sm:space-y-6">

                            <div className="grid grid-cols-1 gap-5 sm:gap-6 lg:grid-cols-6 pb-10">
                                <div className="overflow-hidden bg-white border border-gray-200 rounded-xl lg:col-span-4">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="sm:flex sm:items-start sm:justify-between">
                                            <div className="flex w-full">
                                                <div className="w-full">
                                                    <p className="text-base font-bold text-gray-900">Pending Product</p>
                                                    <p className="mt-1 text-sm font-medium text-gray-500">List of pending product request.</p>
                                                </div>
                                                <div className="text-end w-full self-center">
                                                    <button onClick={() => {
                                                        console.log(dataCategory);
                                                        // // interest array object to array string
                                                        // var dataCate = data?.categories.map(function (item) {
                                                        //     return item['_id'];
                                                        // });

                                                        // setCategoryProduct(dataCate);
                                                        setOpenCreate(true)
                                                    }} className="text-sm drop-shadow-md px-2 py-1 text-gray-900 border rounded hover:bg-gray-50 transition-all duration-150">Add New +</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full text-end px-6">
                                        <button className="border border-gray-300 rounded py-1 px-2 mr-4" onClick={() => {
                                            getData();
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><title>ionicons-v5-b</title><path d="M320,146s24.36-12-64-12A160,160,0,1,0,416,294" style={{ fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeMiterlimit: '10', strokeWidth: '32px' }} /><polyline points="256 58 336 138 256 218" style={{ fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeMiterlimit: '10', strokeWidth: '32px' }} /></svg>
                                        </button>
                                    </div>

                                    <div className="divide-y divide-gray-200" style={{
                                        height: '50vh',
                                        overflowY: 'auto'
                                    }}>

                                        {productData.map((data, index) => {
                                            return (
                                                <div key={index} className="grid grid-cols-3 py-4 lg:py-0 gap-y-4 lg:gap-0 lg:grid-cols-6">
                                                    <div className="col-span-2 px-4 lg:py-4 sm:px-6 lg:col-span-1 centered-flex place-content-center" style={{ justifySelf: 'center' }}>
                                                        <div className="lg:py-4 self-center">
                                                            <img className="mr-4 flex-shrink-0 object-cover w-8 h-8 rounded-full" alt="" style={{ margin: '0 auto' }} src={'https://api-launchy.rshme.codes/storage/' + data.logo} />
                                                        </div>
                                                    </div>

                                                    <div className="px-4 text-left md:text-right lg:py-4 sm:px-6 lg:order-last self-center space-x-1">
                                                        <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 font-bold bg-green-200"
                                                            onClick={() => {
                                                                verifiedCheck('verify', data.slug, data.name, data.creator.wallet_address);
                                                            }}>
                                                            <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" fill="white"></path>
                                                            </svg>
                                                        </button>
                                                        <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 font-bold bg-blue-200"
                                                            onClick={() => {

                                                                // interest array object to array string
                                                                var dataCate = data?.categories.map(function (item) {
                                                                    return item['_id'];
                                                                });

                                                                setCategoryProduct(dataCate);
                                                                setSlugProduct(data.slug);
                                                                setxwalletaddressProduct(data.creator.wallet_address);
                                                                setNameProduct(data.name);
                                                                setSummaryProduct(data.tagline);
                                                                setDescriptionProduct(data.description);
                                                                setWebsiteProduct(data.website);
                                                                setEmailProduct(data.email);
                                                                setTwitterProduct(data.twitter_url);
                                                                setYoutubeProduct(data.youtube_url);
                                                                setLogoProdEd(data.logo);
                                                                setBannerProdEd(data.banners);
                                                                setDateProduct(data.approved_at);

                                                                setOpen(true);
                                                            }}>
                                                            <svg style={{ color: 'white' }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L6.50002 2.79288L12.2071 8.49998L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L9.85355 0.146447Z" fill="white"></path> <path d="M0 9.29289L5.79291 3.49998L11.5 9.20709L5.70711 15H0.5C0.223858 15 0 14.7761 0 14.5V9.29289Z" fill="white"></path> <path d="M8 15H15V14H8V15Z" fill="white"></path>
                                                            </svg>
                                                        </button>
                                                    </div>

                                                    <div className="px-4 lg:py-4 sm:px-6 self-center">
                                                        <p className="text-sm font-bold text-gray-900 truncate">{data.name}</p>
                                                    </div>

                                                    <div className="lg:col-span-2 px-4 lg:py-4 sm:px-6 self-center">
                                                        {/* <td dangerouslySetInnerHTML={{__html: html}} /> */}
                                                        <p className="mt-1 text-sm font-medium text-gray-500 truncate">{data.tagline}</p>
                                                    </div>

                                                    <div className="px-4 lg:py-4 sm:px-6 self-center">
                                                        <p className="mt-1 text-sm font-medium text-gray-500 truncate">{data.website}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className="overflow-hidden bg-white border border-gray-200 rounded-xl lg:col-span-2">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div>
                                            <p className="text-base font-bold text-gray-900">Live Product</p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">List of live product.</p>
                                        </div>

                                        <div className="mt-8 space-y-6" style={{
                                            height: '50vh',
                                            overflowY: 'auto'
                                        }}>

                                            {productDataLive.map((data, index) => {
                                                return (
                                                    <div className="flex items-center justify-between space-x-5">
                                                        <div className="col-span-2 px-4 sm:px-6 lg:col-span-1 centered-flex place-content-center" style={{ justifySelf: 'center' }}>
                                                            <div className="self-center">
                                                                <img className="mr-4 flex-shrink-0 object-cover w-8 h-8 rounded-full" alt="" style={{ margin: '0 auto' }} src={'https://api-launchy.rshme.codes/storage/' + data.logo} />
                                                            </div>
                                                        </div>
                                                        <div className="px-4 sm:px-6 self-center">
                                                            <p className="text-sm font-bold text-gray-900">{data.name}</p>
                                                        </div>

                                                        <div className="text-right">
                                                            <button type="button" className="mx-2 inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 font-bold bg-red-200"
                                                                onClick={() => {
                                                                    verifiedCheck('remove', data.slug, data.name, data.creator.wallet_address);
                                                                }}
                                                            >
                                                                ✖
                                                            </button>
                                                            <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 font-bold bg-blue-200"
                                                                onClick={() => {

                                                                    // interest array object to array string
                                                                    var dataCate = data?.categories.map(function (item) {
                                                                        return item['_id'];
                                                                    });

                                                                    setCategoryProduct(dataCate);
                                                                    setSlugProduct(data.slug);
                                                                    setxwalletaddressProduct(data.creator.wallet_address);
                                                                    setNameProduct(data.name);
                                                                    setSummaryProduct(data.tagline);
                                                                    setDescriptionProduct(data.description);
                                                                    setWebsiteProduct(data.website);
                                                                    setEmailProduct(data.email);
                                                                    setTwitterProduct(data.twitter_url);
                                                                    setYoutubeProduct(data.youtube_url);
                                                                    setLogoProdEd(data.logo);
                                                                    setBannerProdEd(data.banners);
                                                                    setDateProduct(data.approved_at);

                                                                    setOpen(true);
                                                                }}>
                                                                <svg style={{ color: 'white' }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L6.50002 2.79288L12.2071 8.49998L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L9.85355 0.146447Z" fill="white"></path> <path d="M0 9.29289L5.79291 3.49998L11.5 9.20709L5.70711 15H0.5C0.223858 15 0 14.7761 0 14.5V9.29289Z" fill="white"></path> <path d="M8 15H15V14H8V15Z" fill="white"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>)
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* ================================== Update ================================== */}
            <Dialog
                fullWidth='true'
                maxWidth='md'
                open={open}
            >
                <DialogContent>
                    <DialogContentText>
                        <div className="mb-6">
                            {logoProdEd !== null ?
                                <img className="mr-4 flex-shrink-0 object-cover w-20 h-20 rounded-full" alt="" src={'https://api-launchy.rshme.codes/storage/' + logoProdEd} />
                                : ""}
                            <label className="block mb-2 text-sm font-medium text-gray-900">Change Product Profile Image</label>
                            {logoProduct !== null ?
                                <div>
                                    <img src={URL.createObjectURL(logoProduct)} style={{ height: '10vh' }} alt="" />
                                    <button className="mt-2 text-sm text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-900 font-medium rounded-lg w-full sm:w-auto px-4 py-1.5 text-center" onClick={() => { setLogoProduct(null) }}>Remove</button>
                                </div>
                                :
                                <div class="flex items-center justify-center w-full lg:w-6/12">
                                    <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                        <div class="flex flex-col items-center justify-center pt-7">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path fill-rule="evenodd"
                                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                Change Profile Image</p>
                                        </div>
                                        <input type="file" onChange={(event) => { setLogoProduct(event.target.files[0]) }} class="opacity-0" />
                                    </label>
                                </div>
                            }
                        </div>
                        <div className="mb-6">
                            <div className="grid grid-cols-3">
                                {BannerProdEd.map(function (item, i) {
                                    return (
                                        <img className="mr-4 object-cover w-full px-2" alt="" src={'https://api-launchy.rshme.codes/storage/' + item} />
                                    )
                                })}
                            </div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">Change Product Gallery</label>
                            {bannerProductURLs.length !== 0 ?
                                <div>
                                    <div className="grid grid-cols-3">
                                        {bannerProduct.map(function (item, i) {
                                            return (
                                                <img className="px-2 mt-2" src={URL.createObjectURL(item)} style={{ width: '100%' }} alt="" />
                                            )
                                        })}
                                        <div class="flex items-center justify-center w-full lg:w-6/12 mt-2">
                                            <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                                <div class="flex flex-col items-center justify-center pt-7">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                        fill="currentColor">
                                                        <path fill-rule="evenodd"
                                                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                    <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Add more</p>
                                                </div>
                                                <input type="file" onChange={(event) => {
                                                    setBannerProductURLs([...event.target.files])
                                                }} class="opacity-0" accept="image/*" />
                                            </label>
                                        </div>
                                    </div>
                                    <button className="mt-2 text-sm text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-900 font-medium rounded-lg w-full sm:w-auto px-4 py-1.5 text-center" onClick={() => { setBannerProduct([]); setBannerProductURLs([]); }}>Remove</button>
                                </div>
                                :
                                <div class="flex items-center justify-center w-full lg:w-6/12">
                                    <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                        <div class="flex flex-col items-center justify-center pt-7">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path fill-rule="evenodd"
                                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                Change Gallery Image</p>
                                        </div>
                                        <input type="file" onChange={(event) => {
                                            setBannerProductURLs([...event.target.files])
                                        }} class="opacity-0" accept="image/*" />
                                    </label>
                                </div>
                            }
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Name</label>
                            <input value={nameProduct} onChange={(e) => { setNameProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Summary</label>
                            <input value={summaryProduct} onChange={(e) => { setSummaryProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Description</label>
                            <Trumbowyg data={descriptionProduct} onPaste={(e) => { setDescriptionProduct(e.target.innerHTML) }} onChange={(e) => { setDescriptionProduct(e.target.innerHTML) }} placeholder="paste here..." id='react-trumbowyg' />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Website</label>
                            <input value={websiteProduct} onChange={(e) => { setWebsiteProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Email</label>
                            <input value={emailProduct} onChange={(e) => { setEmailProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Twitter</label>
                            <input value={twitterProduct} onChange={(e) => { setTwitterProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Youtube</label>
                            <input value={youtubeProduct} onChange={(e) => { setYoutubeProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                            <label className="block mb-2 text-xs text-gray-500 mt-2">Example URL: https://www.youtube.com/watch?v=oQQ4PKaSxFQ</label>
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Category</label>
                            <div className='flex'>
                                <div className='w-full grid grid-cols-2'>
                                    {dataCategory.map(function (item, i) {
                                        return (
                                            <div key={i} className="flex items-center mb-4">
                                                <input
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCategoryProduct([
                                                                ...categoryProduct,
                                                                item._id
                                                            ]);
                                                        } else {
                                                            setCategoryProduct(
                                                                categoryProduct.filter((x) => x.indexOf(item._id) === -1),
                                                            );
                                                        }
                                                    }}
                                                    id="checkbox-2" type="checkbox" value={dataCategory} defaultChecked={categoryProduct?.includes(item._id)} className="w-4 h-4 text-core-900 bg-gray-100 rounded border-gray-300 focus:ring-core-900" />
                                                <label htmlFor="checkbox-2" className="ml-2 text-sm font-medium text-gray-900">{item.name}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='md:space-x-2'>
                            <button onClick={() => { editDataProduct(slugProduct, xwalletaddressProduct) }} type="button" className="text-white bg-blue-700 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
                            <button onClick={() => { deleteDataProduct(slugProduct, xwalletaddressProduct); }} type="button" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Delete</button>
                            <button onClick={() => { clearAllForm(); setOpen(false); }} type="button" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Close</button>
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >

            {/* ================================== Create ================================== */}
            <Dialog
                fullWidth='true'
                maxWidth='md'
                open={openCreate}
            >
                <DialogContent>
                    <DialogContentText>
                        <div>
                            <h1 className="font-bold text-xl">Create Product</h1>
                        </div>
                        <hr />
                        <div className="mt-4 mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Profile Image</label>
                            {logoProduct !== null ?
                                <div>
                                    <img src={URL.createObjectURL(logoProduct)} style={{ height: '10vh' }} alt="" />
                                    <button className="mt-2 text-sm text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-900 font-medium rounded-lg w-full sm:w-auto px-4 py-1.5 text-center" onClick={() => { setLogoProduct(null) }}>Remove</button>
                                </div>
                                :
                                <div class="flex items-center justify-center w-full lg:w-6/12">
                                    <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                        <div class="flex flex-col items-center justify-center pt-7">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path fill-rule="evenodd"
                                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                Select a photo</p>
                                        </div>
                                        <input type="file" onChange={(event) => { setLogoProduct(event.target.files[0]) }} class="opacity-0" />
                                    </label>
                                </div>
                            }
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Gallery</label>
                            {bannerProductURLs.length !== 0 ?
                                <div>
                                    <div className="grid grid-cols-3">
                                        {bannerProduct.map(function (item, i) {
                                            return (
                                                <img className="px-2 mt-2" src={URL.createObjectURL(item)} style={{ width: '100%' }} alt="" />
                                            )
                                        })}
                                        <div class="flex items-center justify-center w-full lg:w-6/12 mt-2">
                                            <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                                <div class="flex flex-col items-center justify-center pt-7">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                        fill="currentColor">
                                                        <path fill-rule="evenodd"
                                                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                    <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Add more</p>
                                                </div>
                                                <input type="file" onChange={(event) => {
                                                    setBannerProductURLs([...event.target.files])
                                                }} class="opacity-0" accept="image/*" />
                                            </label>
                                        </div>
                                    </div>
                                    <button className="mt-2 text-sm text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-900 font-medium rounded-lg w-full sm:w-auto px-4 py-1.5 text-center" onClick={() => { setBannerProduct([]); setBannerProductURLs([]); }}>Remove</button>
                                </div>
                                :
                                <div class="flex items-center justify-center w-full lg:w-6/12">
                                    <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                        <div class="flex flex-col items-center justify-center pt-7">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path fill-rule="evenodd"
                                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                Select Gallery Image</p>
                                        </div>
                                        <input type="file" onChange={(event) => {
                                            setBannerProductURLs([...event.target.files])
                                        }} class="opacity-0" accept="image/*" />
                                    </label>
                                </div>
                            }
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Name</label>
                            <input value={nameProduct} onChange={(e) => { setNameProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Tagline</label>
                            <input value={summaryProduct} onChange={(e) => { setSummaryProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Description</label>
                            <Trumbowyg onPaste={(e) => { setDescriptionProduct(e.target.innerHTML) }} onChange={(e) => { setDescriptionProduct(e.target.innerHTML) }} placeholder="paste here..." id='react-trumbowyg' />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Website</label>
                            <input value={websiteProduct} onChange={(e) => { setWebsiteProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="https://" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Email</label>
                            <input value={emailProduct} onChange={(e) => { setEmailProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="youremail@gmail.com" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Twitter</label>
                            <input value={twitterProduct} onChange={(e) => { setTwitterProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="https://" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Youtube(Optional)</label>
                            <input value={youtubeProduct} onChange={(e) => { setYoutubeProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="https://" />
                            <label className="block mb-2 text-xs text-gray-500 mt-2">Example URL: https://www.youtube.com/watch?v=oQQ4PKaSxFQ</label>
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Category</label>
                            <div className='flex'>
                                <div className='w-full grid grid-cols-2'>
                                    {dataCategory.map(function (item, i) {
                                        return (
                                            <div key={i} className="flex items-center mb-4">
                                                <input
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCategoryProduct([
                                                                ...categoryProduct,
                                                                item._id
                                                            ]);
                                                        } else {
                                                            setCategoryProduct(
                                                                categoryProduct.filter((x) => x.indexOf(item._id) === -1),
                                                            );
                                                        }
                                                    }}
                                                    id="checkbox-2" type="checkbox" value={dataCategory} className="w-4 h-4 text-core-900 bg-gray-100 rounded border-gray-300 focus:ring-core-900" />
                                                <label htmlFor="checkbox-2" className="ml-2 text-sm font-medium text-gray-900">{item.name}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='md:space-x-2'>
                            <button onClick={() => { addDataProduct(); }} type="button" className="text-white bg-blue-700 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Create</button>
                            <button onClick={() => { clearAllForm(); setOpenCreate(false); }} type="button" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Close</button>
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >

            {
                isToast &&
                <div id="toast-bottom-right" style={{ zIndex: '1500' }} className="bg-red-500 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                    <div className="text-sm font-normal flex items-center">
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                        </svg>
                        <span>{toastMessage}</span>
                    </div>
                </div>
            }
        </div>
    );
}