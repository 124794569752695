import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../resources/supabase";
import 'react-trumbowyg/dist/trumbowyg.min.css';

export function CreateAffiliate({ }) {
    const navigate = useNavigate();
    const [linkContent, setLinkContent] = useState('');
    const [titleContent, setTitleContent] = useState('');
    const [subtitleContent, setSubtitleContent] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [stringArray, setStringArray] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);

    function handleAddString() {
        if (inputValue) {
            setStringArray([...stringArray, inputValue]);
            setInputValue('');
        }
    }

    function handleRemoveString(index) {
        const updatedArray = [...stringArray];
        updatedArray.splice(index, 1);
        setStringArray(updatedArray);
    }

    function handleDropdownChange(e) {
        const selectedValue = e.target.value;
        if (selectedValue) {
            setStringArray([...stringArray, selectedValue]);
        }
    }

    const [toastMessage, setToastMessage] = useState('');
    const [isToast, setIsToast] = useState(false);

    const clearAllForm = () => {
        setTitleContent('');
        setSubtitleContent('');
        setLinkContent('');
    };

    const addDataStories = async () => {
        try {
            await supabase
                .from('affiliate')
                .insert([{
                    title: titleContent,
                    subtitle: subtitleContent,
                    link: linkContent,
                    isPublished: false,
                    category: stringArray
                }])

            let uniqueValues = stringArray.filter(value => !categoriesData.includes(value));

            for (let i = 0; i < uniqueValues.length; i++) {
                await supabase
                    .from('affiliateCategory')
                    .insert([{
                        name: uniqueValues[i],
                    }])
            }

            clearAllForm();
            setIsToast(true);
            setToastMessage('Successfully Posted!');
            navigate('/launchy/affiliate-management');
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
            return;
        }
    }

    const getCategories = async (e) => {
        let { data: affiliateCategoryData, error } = await supabase
            .from('affiliateCategory')
            .select('*')

        let dataArray = [];
        for (let i = 0; i < affiliateCategoryData.length; i++) {
            dataArray.push(affiliateCategoryData[i].name)
        }
        setCategoriesData(dataArray);
    };

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    return (
        <div className="flex flex-col flex-1 overflow-x-hidden h-screen">
            <main>
                <div className="py-6">
                    <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">
                        <Link to="/launchy/affiliate-management" onClick={() => {
                            setTitleContent('');
                            setSubtitleContent('');
                            setLinkContent('');
                        }} title="" className="mb-2 flex items-center px-4 py-2.5 text-sm font-medium transition-all duration-200 text-gray-900 rounded-lg hover:bg-gray-200 focus:bg-gray-200 group" style={{ width: 'fit-content' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </Link>
                        <div className="mt-6">
                            <label class="inline-block text-gray-500">Category</label>
                            <div>
                                <input
                                    type="text"
                                    className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white p-2.5"
                                    value={inputValue}
                                    onChange={(e) => { setInputValue(e.target.value) }}
                                    placeholder="Enter a new Category"
                                />
                                <button className="px-2 py-1 rounded-md bg-green-400 text-white font-bold" onClick={handleAddString}>Add</button>
                                <select value={selectedValue} onChange={handleDropdownChange} className="ml-2 mt-2 bg-white border border-gray-300 rounded-md pl-4 pr-10 py-1">
                                    <option value="" selected disabled hidden>Select Existing Category</option>
                                    {categoriesData.map((data, index) => {
                                        return (
                                            <option value={`${data}`}>{data}</option>
                                        )
                                    })}
                                </select>
                                <div>
                                    {stringArray.map((item, index) => (
                                        <div
                                            className="bg-red-400 text-white flex"
                                            key={index}
                                            style={{
                                                display: 'inline-block',
                                                margin: '5px',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span className="pl-1 pr-3">{item}</span>
                                            <button className="ml-1 bg-red-600 rounded-full font-black w-6 h-6" onClick={() => handleRemoveString(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x mx-auto" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mt-6">
                            <label class="inline-block text-gray-500">Link</label>
                            <input value={linkContent} onChange={(e) => { setLinkContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="Link" />
                        </div>
                        <div className="mt-6">
                            <label class="inline-block text-gray-500">Title</label>
                            <input value={titleContent} onChange={(e) => { setTitleContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="Title" />
                        </div>
                        <div className="mt-6 mb-4">
                            <label class="inline-block text-gray-500">Subtitle</label>
                            <input value={subtitleContent} onChange={(e) => { setSubtitleContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="Subtitle" />
                        </div>
                        <button className="mt-4 text-sm text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-500 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center" onClick={() => {
                            addDataStories();
                        }}>Submit</button>
                    </div>
                </div>
            </main>

            {
                isToast &&
                <div id="toast-bottom-right" style={{ zIndex: '1500' }} className="bg-red-500 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                    <div className="text-sm font-normal flex items-center">
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                        </svg>
                        <span>{toastMessage}</span>
                    </div>
                </div>
            }
        </div>
    );
}