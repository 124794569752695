import './App.css';
import { CreateStories } from './components/CreateStories';
import { SideBar } from './components/SideBar';
import { StoriesManagementET } from './components/EmergingTech/StoriesManagement';
import { CreateStoriesET } from './components/EmergingTech/CreateStories';
import { StoriesManagement } from './components/StoriesManagement';
import { CollabRequest } from './components/CollabRequest';
import { ProductManagement } from './components/ProdutManagement';
import { SubscribeManagement } from './components/SubscribeManagement';
import { Dashboard } from './components/Dashboard';
import { Header } from './components/Header';
import { ethers } from "ethers";
import launchyImg from './assets/launchy.png'
import {
  Routes,
  Route,
} from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
} from "firebase/firestore";
import { db } from "./resources/firebase";
import { AffiliateManagement } from './components/AffiliateManagement';
import { CreateAffiliate } from './components/CreateAffiliate';

function App() {
  const [adminData, setAdminData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [savedAddress, setSavedAddress] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [toastMessage, setToastMessage] = useState('');
  const [isToast, setIsToast] = useState(false);

  const verificationAdmin = async () => {
    let data = adminData.filter(function (result) {
      return result.username === username && result.password === password;
    });
    console.log(data);
    if (data.length === 0) {
      setIsToast(true);
      setToastMessage('Incorrect password.');
    } else {
      setIsAdmin(true);
      window.sessionStorage.setItem('username_svd', username);
      window.sessionStorage.setItem('password_svd', password);
    }
  }

  const getDataAdmin = async () => {
    const colRefget = collection(db, "adminDashboard");
    const recordget = query(colRefget);
    getDocs(recordget).then((snap) => {
      setAdminData(snap.docs.map((d) => ({ id: d.id, ...d.data() })));
    });
  };

  const isSaved = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    console.log(address);
    if (window.localStorage.getItem("savedAddress") === address) {
      setSavedAddress(true);
    }
  }

  useEffect(() => {
    if (!isToast) return;

    const intervalId = setInterval(() => {
      setIsToast(false);
      setToastMessage('');
    }, 5000);

    return () => clearInterval(intervalId);
  }, [isToast]);

  useEffect(() => {
    (async function () {
      getDataAdmin();
      console.log(window.sessionStorage.getItem("username_svd"));
      console.log(window.sessionStorage.getItem("password_svd"));
      if (window.sessionStorage.getItem("username_svd") !== null && window.sessionStorage.getItem("password_svd") !== null) {
        setIsAdmin(true);
      }
    })();
  }, []);

  return (
    <>
      {/* {!isAdmin ? */}
        <div className="flex flex-col">
          <Header />

          <div className="flex flex-1">
            <SideBar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/launchy/collab-request" element={<CollabRequest />} />
              <Route path="/launchy/product-management" element={<ProductManagement />} />
              <Route path="/launchy/subscribe-management" element={<SubscribeManagement />} />
              <Route path="/launchy/stories-management" element={<StoriesManagement />} />
              <Route path="/launchy/create-stories" element={<CreateStories />} />
              <Route path="/launchy/affiliate-management" element={<AffiliateManagement />} />
              <Route path="/launchy/create-affiliate" element={<CreateAffiliate />} />
              <Route path="/emerging-tech/stories-management" element={<StoriesManagementET />} />
              <Route path="/emerging-tech/create-stories" element={<CreateStoriesET />} />
            </Routes>
          </div>
        </div>
        {/* :
        <section>
          <div className="min-h-screen lg:flex lg:justify-between">
            <div className="flex flex-col self-center justify-center flex-1 px-4 py-12 bg-white sm:px-6 lg:px-20 xl:px-24">
              <div className="flex-1 max-w-sm mx-auto lg:max-w-md">
                <div className='flex'>
                  <img className="w-auto h-8 mx-auto lg:mx-0" src={launchyImg} alt="" />
                  <h1 className='text-xl mx-2 font-bold'>Launchy</h1>
                </div>

                <h1 className="mt-10 text-3xl font-bold text-center text-gray-900 lg:mt-20 xl:mt-26 sm:text-4xl xl:text-5xl font-pj lg:text-left">Welcome to Launchy Dashboard</h1>

                <form action="#" method="POST" className="mt-10">

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                      Username
                    </label>
                    <input onChange={(e) => { setUsername(e.target.value) }} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" />
                  </div>
                  <div class="mb-6">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                      Password
                    </label>
                    <input onChange={(e) => { setPassword(e.target.value) }} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="Password" />

                    {
                      isToast &&
                      <p class="text-red-500 text-xs italic">{toastMessage}</p>
                    }
                  </div>
                  <div className="relative mt-8">
                    <div className="absolute -inset-2">
                      <div className="w-full h-full mx-auto opacity-30 blur-lg filter" style={{ background: 'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)' }}></div>
                    </div>
                    <button
                      type="button"
                      className="relative flex items-center justify-center w-full px-8 py-4 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600"
                      onClick={() => {
                        verificationAdmin();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="relative grid flex-1 w-full px-4 py-12 overflow-hidden bg-gray-900 lg:max-w-2xl lg:px-20 xl:px-24 sm:px-6 place-items-center">
              <div className="absolute inset-0">
                <img className="object-cover object-top w-full h-full scale-150 -rotate-90 opacity-10" src="https://cdn.rareblocks.xyz/collection/clarity/images/sign-up/4/background-pattern.png" alt="" />
              </div>

              <div className="relative max-w-sm mx-auto">
                <div className="inline-flex items-center justify-center w-20 h-20 bg-gray-800 rounded-xl">
                  <svg className="w-auto h-5 text-white" viewBox="0 0 33 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.0011 4.7203L30.9745 0C23.5828 0.33861 18.459 3.41404 18.459 12.4583V22.8687H31.3725V9.78438H26.4818C26.4819 6.88236 28.3027 5.17551 32.0011 4.7203Z" />
                    <path d="M13.5421 4.7203L12.5155 0C5.12386 0.33861 0 3.41413 0 12.4584V22.8687H12.914V9.78438H8.02029C8.02029 6.88236 9.84111 5.17551 13.5421 4.7203Z" />
                  </svg>
                </div>

                <blockquote className="mt-14">
                  <p className="text-2xl font-medium leading-relaxed text-white lg:text-3xl font-pj">“This dashboard provides administrators direct access to important Encompass tools for faster and more convenient community management.”</p>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
      } */}
    </>
  );
}

export default App;
