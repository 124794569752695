import { useEffect, useState } from "react";
import axios from 'axios';

export function SubscribeManagement() {
    const [dataSubs, setDataSubs] = useState([]);

    const getData = async () => {
        const response = await axios.get(`https://api-launchy.rshme.codes/api/v1/user?is_subscribed=true`, {
            headers: {
                'Content-Type': 'application/json',
                'X-Wallet-Address': '0x49562cCc2BE090B9A7957AE156af9Cc5dbd14765'
            }
        }).catch(function (error) {
            console.log(error);
        });
        setDataSubs(response.data.data);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="flex flex-col flex-1 overflow-x-hidden">
            <main>
                <div className="py-6">
                    <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">
                        <div className="space-y-5 sm:space-y-6">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="sm:flex sm:items-start sm:justify-between">
                                    <div>
                                        <p className="text-base font-bold text-gray-900">Subscribed Users</p>
                                        <p className="mt-1 text-sm font-medium text-gray-500">List of Subscribed Users.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="divide-y divide-gray-200" style={{
                                height: '50vh',
                                overflowY: 'auto'
                            }}>

                                {dataSubs.map((data, index) => {
                                    return (
                                        <div key={index} className="grid grid-cols-3 gap-y-4 lg:gap-0 lg:grid-cols-5">
                                            <div className="col-span-2 px-4 lg:py-4 sm:px-6 lg:col-span-1 centered-flex place-content-center" style={{ justifySelf: 'center' }}>
                                                <div className="self-center">
                                                    {data.photo !== null ?
                                                        <img className="mr-4 flex-shrink-0 object-cover w-8 h-8 rounded-full" alt="" style={{ margin: '0 auto' }} src={'https://api-launchy.rshme.codes/storage/' + data.photo} />
                                                        :
                                                        <div className="mr-4 flex-shrink-0 object-cover w-8 h-8 rounded-full bg-gray-300" style={{ margin: '0 auto' }} ></div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="px-4 lg:py-4 sm:px-6 self-center">
                                                <p className="text-sm font-bold text-gray-900 truncate">{data.name}</p>
                                            </div>

                                            <div className="lg:col-span-2 px-4 lg:py-4 sm:px-6 self-center">
                                                <p className="mt-1 text-sm font-medium text-gray-500 truncate">{data.email}</p>
                                            </div>

                                            <div className="px-4 lg:py-4 sm:px-6 self-center">
                                                <p className="mt-1 text-sm font-medium text-gray-500 truncate">{data.location}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}