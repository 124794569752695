import { useEffect, useState } from "react";
import {
    Link,
} from "react-router-dom";
import axios from 'axios';
import { supabase } from "../../resources/supabase_et";
import { uid } from 'uid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Trumbowyg from 'react-trumbowyg';
import 'react-trumbowyg/dist/trumbowyg.min.css';

export function StoriesManagementET({ }) {
    const [storiesContent, setStoriesContent] = useState([]);
    const [openPostDetail, setOpenPostDetail] = useState(false);
    const [slugContent, setSlugContent] = useState('');
    const [idContent, setIdContent] = useState('');
    const [authorContent, setAuthorContent] = useState('');
    const [titleContent, setTitleContent] = useState('');
    const [subtitleContent, setSubtitleContent] = useState('');
    const [contentStories, setContentStories] = useState('');
    const [walletPost, setWalletPost] = useState('');
    const [previewImage, setPreviewImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [dataCategory, setDataCategory] = useState([]);
    const [categoryPost, setCategoryPost] = useState([]);
    const [stringArray, setStringArray] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState('');

    function handleAddString() {
        if (inputValue) {
            setStringArray([...stringArray, inputValue]);
            setInputValue('');
        }
    }

    function handleRemoveString(index) {
        const updatedArray = [...stringArray];
        updatedArray.splice(index, 1);
        setStringArray(updatedArray);
    }

    function handleDropdownChange(e) {
        const selectedValue = e.target.value;
        if (selectedValue) {
            setStringArray([...stringArray, selectedValue]);
        }
    }

    const getCategory = async (id) => {
        let { data, error } = await supabase
            .from('news')
            .select('category')
            .eq('id', id)

        console.log(data);
        setStringArray(data[0].category)
    }

    const getCategories = async (e) => {
        let { data: newsCategoryData, error } = await supabase
            .from('newsCategory')
            .select('*')

        let dataArray = [];
        for (let i = 0; i < newsCategoryData.length; i++) {
            dataArray.push(newsCategoryData[i].name)
        }
        setCategoriesData(dataArray);
    };

    useEffect(() => {
        getCategories();
    }, []);

    const [toastMessage, setToastMessage] = useState('');
    const [isToast, setIsToast] = useState(false);

    const clearAllForm = () => {
        setTitleContent('');
        setSubtitleContent('');
        setContentStories('');
        setPreviewImage(null);
        setCategoryPost([]);
        setIdContent('');
        setWalletPost('');
        setSlugContent('');
        setBannerImage('');
    };

    const getDataCategory = async () => {
        const response = await axios.get(`https://api-launchy.rshme.codes/api/v1/category`).catch(function (error) {
            console.log(error);
        });
        setDataCategory(response.data.data);
    }

    const getDataStories = async () => {
        const { data: response } = await supabase
            .from('news')
            .select('*')
            .order('created_at', { ascending: false })

        setStoriesContent(response);
    }

    const getDataStoriesSlug = async (response) => {
        // setSlugContent(response.slug);
        // setWalletPost(response.author.wallet_address);
        setIdContent(response.id);
        setTitleContent(response.title);
        setSubtitleContent(response.subtitle);
        setContentStories(response.content);
        setBannerImage(response.image);
        setAuthorContent(response.author);
        setOpenPostDetail(true);
    }

    const updateStatus = async (id, status) => {
        try {
            if (status === true) {
                await supabase
                    .from('news')
                    .update({ isPublished: true })
                    .eq('id', id)
            } else {
                await supabase
                    .from('news')
                    .update({ isPublished: false })
                    .eq('id', id)
            }

            getDataStories();
        } catch (error) {
            console.log(error);
        }
    }

    const updateDataStories = async (id, bannerImage) => {

        await supabase
            .from('news')
            .update({
                title: titleContent,
                subtitle: subtitleContent,
                content: contentStories,
                tags: null,
                author: authorContent,
                category: stringArray
            })
            .eq('id', id)

        try {
            if (previewImage !== null) {

                var ret = bannerImage.replace('https://alyrujlpojezxmmcalqc.supabase.co/storage/v1/object/public/emergingtech/news/', '');
                await supabase
                    .storage
                    .from('emergingtech')
                    .remove([`news/${ret}`])

                var uidFormat = uid(16);
                var titleFormat = titleContent;
                titleFormat = titleFormat.replaceAll(/[^a-zA-Z ]/g, '-').toLowerCase();
                var finalFormat = titleFormat.replaceAll(' ', '-').toLowerCase();
                var imgName = 'news-' + finalFormat + '-' + uidFormat;
                var titleImage = imgName;

                await supabase
                    .storage
                    .from('emergingtech')
                    .upload(`news/${titleImage}.png`, previewImage, {
                        cacheControl: '3600',
                        upsert: false
                    })

                const { data: getUrl } = supabase
                    .storage
                    .from('emergingtech')
                    .getPublicUrl(`news/${titleImage}.png`)

                await supabase
                    .from('news')
                    .update({
                        image: getUrl.publicUrl
                    })
                    .eq('id', id)
            }

            let uniqueValues = stringArray.filter(value => !categoriesData.includes(value));

            for (let i = 0; i < uniqueValues.length; i++) {
                await supabase
                    .from('newsCategory')
                    .insert([{
                        name: uniqueValues[i],
                    }])
            }

            clearAllForm();
            getDataStories();
            setOpenPostDetail(false)
            setIsToast(true);
            setToastMessage('Successfully Updated!');
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
            setOpenPostDetail(false)
        }
    }

    const deleteDataStories = async (id, bannerImage) => {
        try {
            await supabase
                .from('news')
                .delete()
                .eq('id', id)

            var ret = bannerImage.replace('https://alyrujlpojezxmmcalqc.supabase.co/storage/v1/object/public/emergingtech/news/', '');
            await supabase
                .storage
                .from('emergingtech')
                .remove([`news/${ret}`])

            clearAllForm();
            getDataStories();
            setOpenPostDetail(false);
            setIsToast(true);
            setToastMessage('Story Deleted!');
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    useEffect(() => {
        getDataStories();
        // getDataCategory();
    }, []);

    return (
        <div className="flex flex-col flex-1 overflow-x-hidden">
            <main>
                <div className="py-6">
                    <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">

                        <div className="px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-2 md:grid-cols-0 md:flex md:items-start md:justify-between">
                                <div className="w-full">
                                    <p className="text-base font-bold text-gray-900">Stories</p>
                                    <p className="mt-1 text-sm font-medium text-gray-500">List of Stories.</p>
                                </div>
                                <div className="text-end w-full self-center">
                                    <Link to="/emerging-tech/create-stories" className="text-sm drop-shadow-md px-2 py-1 text-gray-900 border rounded hover:bg-gray-50 transition-all duration-150">Create Stories +</Link>
                                </div>
                            </div>
                        </div>

                        <div className="divide-y divide-gray-200 border border-gray-200 rounded-xl" style={{
                            height: '50vh',
                            overflowY: 'auto'
                        }}>
                            {storiesContent.map((data, index) => {
                                return (
                                    <div key={index} className="grid gap-y-4 py-2 md:grid-cols-4 md:gap-0">
                                        <div className="px-4 lg:py-4 sm:px-6 self-center">
                                            <p className="text-sm font-bold text-gray-900 truncate">{data.title}</p>
                                        </div>

                                        <div className="px-4 lg:py-4 sm:px-6 self-center">
                                            <p className="text-sm text-gray-900 truncate">{data.subtitle}</p>
                                        </div>

                                        <div className="flex cols-span-2 md:justify-center">
                                            <div className="px-4 lg:py-4 sm:px-6 self-center">
                                                {data.isPublished === false ?
                                                    <span class="text-xs font-medium text-yellow-900 bg-yellow-100 rounded-full inline-flex items-center px-2.5 py-1"><svg class="-ml-1 mr-1.5 h-2.5 w-2.5 text-yellow-400" fill="currentColor" viewBox="0 0 8 8"><circle cx="4" cy="4" r="3"></circle></svg>Draft</span>
                                                    :
                                                    <span class="text-xs font-medium text-green-900 bg-green-100 rounded-full inline-flex items-center px-2.5 py-1"><svg class="-ml-1 mr-1.5 h-2.5 w-2.5 text-green-400" fill="currentColor" viewBox="0 0 8 8"><circle cx="4" cy="4" r="3"></circle></svg>Publish</span>
                                                }
                                            </div>

                                            <div className="md:hidden px-4 lg:py-4 sm:px-6 self-center space-x-1 md:text-center" style={{ minWidth: 'fit-content' }}>
                                                <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 font-bold bg-blue-200"
                                                    onClick={() => {
                                                        getDataStoriesSlug(data);
                                                        getCategory(data.id);
                                                    }}>
                                                    <svg style={{ color: 'white' }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L6.50002 2.79288L12.2071 8.49998L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L9.85355 0.146447Z" fill="white"></path> <path d="M0 9.29289L5.79291 3.49998L11.5 9.20709L5.70711 15H0.5C0.223858 15 0 14.7761 0 14.5V9.29289Z" fill="white"></path> <path d="M8 15H15V14H8V15Z" fill="white"></path>
                                                    </svg>
                                                </button>

                                                {data.isPublished ?
                                                    <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 font-bold bg-red-400"
                                                        onClick={() => {
                                                            updateStatus(data.id, false);
                                                        }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                                                        </svg>
                                                    </button>
                                                    :
                                                    <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 font-bold bg-green-200"
                                                        onClick={() => {
                                                            updateStatus(data.id, true);
                                                        }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" fill="white"></path>
                                                        </svg>
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <div className="hidden md:block px-4 lg:py-4 sm:px-6 self-center space-x-1 md:text-center" style={{ minWidth: 'fit-content' }}>
                                            <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 font-bold bg-blue-400"
                                                onClick={() => {
                                                    getDataStoriesSlug(data);
                                                    getCategory(data.id);
                                                }}>
                                                <svg style={{ color: 'white' }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L6.50002 2.79288L12.2071 8.49998L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L9.85355 0.146447Z" fill="white"></path> <path d="M0 9.29289L5.79291 3.49998L11.5 9.20709L5.70711 15H0.5C0.223858 15 0 14.7761 0 14.5V9.29289Z" fill="white"></path> <path d="M8 15H15V14H8V15Z" fill="white"></path>
                                                </svg>
                                            </button>

                                            {data.isPublished ?
                                                <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 font-bold bg-red-400"
                                                    onClick={() => {
                                                        updateStatus(data.id, false);
                                                    }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                                                    </svg>
                                                </button>
                                                :
                                                <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 font-bold bg-green-200"
                                                    onClick={() => {
                                                        updateStatus(data.id, true);
                                                    }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" fill="white"></path>
                                                    </svg>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </main>

            {/* ================================== Update ================================== */}
            <Dialog
                fullWidth='true'
                maxWidth='lg'
                open={openPostDetail}
            >
                <DialogContent>
                    <DialogContentText>
                        <div class="my-4">
                            <label class="inline-block mb-2 text-gray-500">Preview Image</label>
                            {bannerImage !== null ?
                                <img className="w-full" alt="" src={bannerImage} />
                                : ""}
                            {previewImage !== null ?
                                <div>
                                    <img src={URL.createObjectURL(previewImage)} alt="" />
                                    <button className="mt-2 text-sm text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-900 font-medium rounded-lg w-full sm:w-auto px-4 py-1.5 text-center" onClick={() => { setPreviewImage(null) }}>Remove</button>
                                </div>
                                :
                                <div class="flex items-center justify-center w-full lg:w-6/12 mt-6">
                                    <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                        <div class="flex flex-col items-center justify-center pt-7">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path fill-rule="evenodd"
                                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                Change Preview Image</p>
                                        </div>
                                        <input type="file" onChange={(event) => { setPreviewImage(event.target.files[0]) }} class="opacity-0" />
                                    </label>
                                </div>
                            }
                        </div>
                        <div className="mt-6">
                            <label class="inline-block text-gray-500">Author</label>
                            <input value={authorContent} onChange={(e) => { setAuthorContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="John, Doe & John Doe" />
                        </div>
                        <div className="mt-6">
                            <label class="inline-block text-gray-500">Category</label>
                            <div>
                                <input
                                    type="text"
                                    className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white p-2.5"
                                    value={inputValue}
                                    onChange={(e) => { setInputValue(e.target.value) }}
                                    placeholder="Enter a new Category"
                                />
                                <button className="px-2 py-1 rounded-md bg-green-400 text-white font-bold" onClick={handleAddString}>Add</button>
                                <select value={selectedValue} onChange={handleDropdownChange} className="ml-2 mt-2 bg-white border border-gray-300 rounded-md pl-4 pr-10 py-1">
                                    <option value="" selected disabled hidden>Select Existing Category</option>
                                    {categoriesData.map((data, index) => {
                                        return (
                                            <option value={`${data}`}>{data}</option>
                                        )
                                    })}
                                </select>
                                <div>
                                    {stringArray.map((item, index) => (
                                        <div
                                            className="bg-red-400 text-white flex"
                                            key={index}
                                            style={{
                                                display: 'inline-block',
                                                margin: '5px',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span className="pl-1 pr-3">{item}</span>
                                            <button className="ml-1 bg-red-600 rounded-full font-black w-6 h-6" onClick={() => handleRemoveString(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x mx-auto" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* <input value={authorContent} onChange={(e) => { setAuthorContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="John, Doe & John Doe" /> */}
                        </div>
                        <div className="mt-6">
                            <input value={titleContent} onChange={(e) => { setTitleContent(e.target.value) }} type="text" className="text-3xl bg-white border border-none text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="Title" />
                        </div>
                        <div className="mb-4">
                            <input value={subtitleContent} onChange={(e) => { setSubtitleContent(e.target.value) }} type="text" className="text-xl bg-white border border-none text-gray-400 text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5" placeholder="Subtitle" />
                        </div>
                        <div className="mb-6">
                            <Trumbowyg data={contentStories} onPaste={(e) => { setContentStories(e.target.innerHTML) }} onChange={(e) => { setContentStories(e.target.innerHTML) }} placeholder="paste here..." id='react-trumbowyg' />
                        </div>

                        <div className='md:space-x-2'>
                            <button onClick={() => { updateDataStories(idContent, bannerImage); }} type="button" className="text-white bg-blue-700 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
                            <button onClick={() => { deleteDataStories(idContent, bannerImage); }} type="button" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Delete</button>
                            <button onClick={() => { clearAllForm(); setOpenPostDetail(false); }} type="button" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Close</button>
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >


            {
                isToast &&
                <div id="toast-bottom-right" style={{ zIndex: '1500' }} className="bg-red-500 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                    <div className="text-sm font-normal flex items-center">
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                        </svg>
                        <span>{toastMessage}</span>
                    </div>
                </div>
            }
        </div>
    );
}