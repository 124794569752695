export function Dashboard() {
    return (
        <div className="flex flex-col flex-1 overflow-x-hidden py-12 sm:py-16 lg:py-20 xl:py-20">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-20 xl:gap-x-40">
                    <div>
                        <img className="object-cover w-full rounded-2xl" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/how-it-works/1/girl-with-laptop.png" alt="" />
                    </div>

                    <div className="max-w-xl mx-auto text-center lg:text-left lg:max-w-none">
                        <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Managing & Maintenance app never been so easy!</h2>
                        <p className="mt-8 text-base font-normal text-gray-600 font-pj">Here's the backend to manage and maintenance your frontend app, let's find out what we can manage here!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}