import { db } from "../resources/firebase";
import { useEffect, useState } from "react";
import {
    getDocs,
    collection,
    where,
    query,
    orderBy,
    limit,
    updateDoc,
    doc
} from "firebase/firestore";

export function CollabRequest() {
    const [recentlyData, setRecentlyData] = useState([]);
    const [processedData, setProcessedData] = useState([]);

    // const updateData = async (id, status) => {
    //     const docRef = doc(db, "registerCollaboration", id);
    //     await updateDoc(docRef, {
    //         status: status
    //     });
    //     await getDataPending();
    //     await getDataProcessed();
    // };

    const getDataPending = async () => {
        const colRefget = collection(db, "registerCollaboration");
        const recordget = query(colRefget, where("status", "==", "pending"), limit(50), orderBy("createdAt", "desc"));
        getDocs(recordget).then((snap) => {
            console.log(snap.docs.map((d) => ({ id: d.id, ...d.data() })));
            setRecentlyData(snap.docs.map((d) => ({ id: d.id, ...d.data() })));
        });
    };

    const getDataProcessed = async () => {
        const colRefget = collection(db, "registerCollaboration");
        const recordget = query(colRefget, where("status", "==", "processed"), orderBy("createdAt"));
        getDocs(recordget).then((snap) => {
            console.log(snap.docs.map((d) => ({ id: d.id, ...d.data() })));
            setProcessedData(snap.docs.map((d) => ({ id: d.id, ...d.data() })));
        });
    };

    useEffect(() => {
        getDataPending();
        getDataProcessed();
    }, []);

    return (
        <div className="flex flex-col flex-1 overflow-x-hidden">
            <section className="py-10 bg-white sm:py-16 lg:py-24">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="max-w-2xl mx-auto text-center">
                        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">How does it work?</h2>
                    </div>

                    <div className="relative mt-12 lg:mt-20">
                        <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                            <img className="w-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg" alt="" />
                        </div>

                        <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                            <div>
                                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span className="text-xl font-semibold text-gray-700"> 1 </span>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Email stored to pending list</h3>
                            </div>

                            <div>
                                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span className="text-xl font-semibold text-gray-700"> 2 </span>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Admin process the email</h3>
                            </div>

                            <div>
                                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span className="text-xl font-semibold text-gray-700"> 3 </span>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Click ✓ button to verify if email has been processed</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main>
                <div className="py-6">
                    <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">
                        <div className="space-y-5 sm:space-y-6">

                            <div className="grid grid-cols-1 gap-5 sm:gap-6 lg:grid-cols-6 pb-10">
                                <div className="overflow-hidden bg-white border border-gray-200 rounded-xl lg:col-span-6">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="sm:flex sm:items-start sm:justify-between">
                                            <div>
                                                <p className="text-base font-bold text-gray-900">Pending Collaboration</p>
                                                <p className="mt-1 text-sm font-medium text-gray-500">List of pending collaboration email.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="divide-y divide-gray-200" style={{
                                        height: '50vh',
                                        overflowY: 'auto'
                                    }}>

                                        {recentlyData.map((data, index) => {
                                            return (
                                                <div className="grid grid-cols-4 py-4 gap-y-4 lg:gap-0 lg:grid-cols-4">
                                                    <div className="col-span-2 px-4 lg:py-4 sm:px-6 lg:col-span-1 self-center">
                                                        <span className="text-xs font-medium text-yellow-900 bg-yellow-100 rounded-full inline-flex items-center px-2.5 py-1">
                                                            <svg className="-ml-1 mr-1.5 h-2.5 w-2.5 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
                                                                <circle cx="4" cy="4" r="3"></circle>
                                                            </svg>
                                                            {data.status === 'pending' && 'Pending'}
                                                        </span>
                                                    </div>

                                                    <div className="px-4 lg:py-4 sm:px-6 self-center text-center lg:order-last">
                                                        <p className="text-sm font-bold text-gray-900 truncate">{data.referring}</p>
                                                    </div>

                                                    {/* <div className="px-4 text-right lg:py-4 sm:px-6 lg:order-last self-center">
                                                        <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 font-bold bg-green-200"
                                                            onClick={() => {
                                                                updateData(data.id, 'processed');
                                                            }}>
                                                            <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" fill="white"></path>
                                                            </svg>
                                                        </button>
                                                    </div> */}

                                                    <div className="col-span-2 px-4 lg:py-4 sm:px-6 lg:col-span-1 self-center">
                                                        <p className="text-sm font-bold text-gray-900 truncate">{data.email}</p>
                                                    </div>

                                                    <div className="px-4 lg:py-4 sm:px-6 self-center">
                                                        <p className="mt-1 text-sm font-medium text-gray-500">{data.createdAt}</p>
                                                    </div>
                                                </div>)
                                        })}
                                    </div>
                                </div>

                                {/* <div className="overflow-hidden bg-white border border-gray-200 rounded-xl lg:col-span-2">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div>
                                            <p className="text-base font-bold text-gray-900">Processed Collaboration</p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">List of processed collaboration email.</p>
                                        </div>

                                        <div className="mt-8 space-y-6" style={{
                                            height: '50vh',
                                            overflowY: 'auto'
                                        }}>

                                            {processedData.map((data, index) => {
                                                return (
                                                    <div className="flex items-center justify-between space-x-5">
                                                        <div className="flex items-center flex-1 min-w-0">
                                                            <div className="flex-1 min-w-0 ml-4">
                                                                <p className="mt-1 text-sm font-medium text-gray-500 truncate">{data.email}</p>
                                                            </div>
                                                        </div>

                                                        <div className="text-right">
                                                            <span className="text-xs font-medium text-green-900 bg-green-100 rounded-full inline-flex items-center px-2.5 py-1">
                                                                <svg className="-ml-1 mr-1.5 h-2.5 w-2.5 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                                                                    <circle cx="4" cy="4" r="3"></circle>
                                                                </svg>
                                                                Processed ✓
                                                            </span>
                                                            <button type="button" className="mx-2 inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 font-bold bg-red-200"
                                                                onClick={() => {
                                                                    updateData(data.id, 'pending');
                                                                }}
                                                            >
                                                                ✖
                                                            </button>
                                                        </div>
                                                    </div>)
                                            })}

                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}